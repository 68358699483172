import { Footer, FooterAlt, FooterLite, Header } from '@aspendental/shared-components-web';
import { ReactNode } from 'react';

import { AdFooter, App, Maybe, NavigationLink, Page } from '@src/lib/__generated/sdk';
import { IGoogleMap } from '@aspendental/shared-components-web/lib/components/GoogleMap/GoogleMap';

interface LayoutPropsInterface {
	children: ReactNode;
	appData: Maybe<App>;
	footerData: Maybe<AdFooter>;
	isFooterLite?: boolean;
	isFooterAlt?: boolean;
	withSimpleLayout?: boolean;
	locale: string;
	preview: boolean;
	showHeaderNavMenu: boolean;
	breadcrumbsData: Page;
	locationNavigationLinks?: Array<NavigationLink | Page> | undefined;
	withFixedHeader: boolean;
	disableStickyFooterOnMobile: boolean | null;
	disableStickyFooterOnDesktop: boolean | null;
	hideLocation?: boolean;
	hidePrimaryActionButton?: boolean;
}

function getFooter(
	footerData: Maybe<AdFooter>,
	isFooterLite: boolean,
	isFooterAlt: boolean,
	withSimpleLayout: boolean,
	breadcrumbsData: Page | undefined,
	disableStickyFooterOnMobile: boolean | null,
	disableStickyFooterOnDesktop: boolean | null
) {
	if (isFooterLite) {
		return (
			<FooterLite
				disclaimer={footerData?.disclaimer as any}
				footerData={footerData as any}
				breadcrumbsData={breadcrumbsData as any}
			/>
		);
	} else if (isFooterAlt) {
		return <FooterAlt footerData={footerData as any} />;
	}
	return (
		<Footer
			footerData={footerData as any}
			withScheduling={withSimpleLayout}
			breadcrumbsData={breadcrumbsData as any}
			isLightFooter={!!footerData?.isLightFooter}
			disableStickyFooterOnMobile={disableStickyFooterOnMobile}
			disableStickyFooterOnDesktop={disableStickyFooterOnDesktop}
		/>
	);
}

export const Layout = ({
	appData,
	footerData,
	isFooterLite = true,
	isFooterAlt = false,
	withSimpleLayout = false,
	children,
	showHeaderNavMenu,
	breadcrumbsData,
	locationNavigationLinks,
	withFixedHeader,
	disableStickyFooterOnMobile,
	disableStickyFooterOnDesktop,
	hideLocation = false,
	hidePrimaryActionButton = false,
}: LayoutPropsInterface) => {
	return (
		<>
			{appData && (
				<Header
					locationNavigationLinks={locationNavigationLinks}
					appData={appData}
					withOfficeDetails
					showHeaderNavMenu={showHeaderNavMenu}
					phoneNumber={appData?.header?.phoneNumber}
					withFixedHeader={withFixedHeader}
					useHeaderLocationCards={appData?.header?.useHeaderLocationCards}
					hideLocation={hideLocation}
					hidePrimaryActionButton={hidePrimaryActionButton}
					googleMapsForLocationCards={appData?.header?.googleMapsForLocationCards as IGoogleMap}
				/>
			)}
			{children}
			{footerData &&
				getFooter(
					footerData,
					isFooterLite,
					isFooterAlt,
					withSimpleLayout,
					breadcrumbsData,
					disableStickyFooterOnMobile,
					disableStickyFooterOnDesktop
				)}
		</>
	);
};
